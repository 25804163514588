import AngryWomenCollectionImage from "assets/collection.png";
import AngryAbortion from "assets/collection/abortion.png";
import AngryRacism from "assets/collection/racism.png";
import AngryOnlineHarrasment from "assets/collection/online_harrassment.png";
import allLinks from "utils/allLinks";
import OpenseaPng from "assets/opensea.png";

const Collection = () => {
  const handleClickFollow = () => {
    window.open(allLinks.openseaLink, "_blank");
  };

  return (
    <div className="py-5 bg-background" id="description">
      <div className="items-center justify-center lg:flex">
        {/* <div className="hidden lg:w-1/2 xl:w-1/3 lg:block">
          <img
            src={AngryWomenCollectionImage}
            alt="angry-woman-description"
            className=""
          />
        </div> */}
        <div className="px-4 lg:px-10 lg:max-w-7xl">
          <div className="flex justify-center lg:block">
            <p className="pt-5 my-2 pb-8 text-5xl font-semibold lg:text-6xl font-brush text-description text-center">
              GENESIS COLLECTION
            </p>
          </div>
          <div className="flex justify-center lg:flex">
            <div className="hidden lg:w-full xl:w-full lg:block lg:mx-5">
              <img
                src={AngryAbortion}
                alt="angry-woman-abortion"
                className=""
              />
            </div>
            <div className="hidden lg:w-full xl:w-full lg:block lg:mx-5">
              <img src={AngryRacism} alt="angry-woman-racism" className="" />
            </div>
            <div className="hidden lg:w-full xl:w-full lg:block lg:mx-5">
              <img
                src={AngryOnlineHarrasment}
                alt="angry-woman-online"
                className=""
              />
            </div>
          </div>
          <div className="flex justify-center lg:block">
            <div className="items-center flex flex-col my-7">
              <button
                onClick={handleClickFollow}
                className="px-6 py-3 text-xl font-bold text-white bg-teal-700 rounded-full cursor-pointer font-montserrat mt-7 hover:bg-teal-900"
              >
                <div className="flex align-middle">
                  <div className="pt-1 pr-2">OpenSea</div>
                  <span className="pt-1">
                    <img src={OpenseaPng} alt="Opensea link" />
                  </span>
                </div>
              </button>
            </div>
          </div>
          <p className="pt-5 lg:leading-10 text-l lg:text-xl font-montserrat">
            We started the Angry Women journey by creating traits for an
            ambitious generative profile pic collection of pissed-off Women.
            During the process, we quickly felt we needed to give a backstory to
            our Angry Women.
          </p>
          <p className="pt-5 lg:leading-10 text-l lg:text-xl font-montserrat">
            Why are they angry? What are they mad about?
          </p>
          <p className="pt-5 lg:leading-10 text-l lg:text-xl font-montserrat pb-10">
            We created our Genesis collection to raise awareness of
            gender-related topics in the NFT space with intersectional feminist
            values in mind.
          </p>
        </div>
        <div className="px-2 pb-5 lg:hidden">
          <img src={AngryWomenCollectionImage} alt="angry-woman-description" />
        </div>
      </div>
    </div>
  );
};

export default Collection;
