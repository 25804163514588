import { useState } from "react";
import TwitterPng from "assets/twitter.png";
import InstagramPng from "assets/instagram.png";
import DiscordPng from "assets/discord.png";
import OpenseaPng from "assets/opensea.png";

import LogoPng from "assets/logo.png";

import allLinks from "utils/allLinks";
const Navbar = () => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);

  const handleClickHome = () => {
    setIsBurgerMenuOpen(false);
  };

  const handleClickBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleClickInstagram = () => {
    window.open(allLinks.instagramLink, "_blank");
  };

  const handleClickTwitter = () => {
    window.open(allLinks.twitterLink, "_blank");
  };

  const handleClickDiscord = () => {
    window.open(allLinks.discordLink, "_blank");
    // alert('Private for now!');
  };

  const handleClickOpensea = () => {
    window.open(allLinks.openseaLink, "_blank");
  };

  const scrollToSection = (idName: string) => {
    const divElement = document.getElementById(idName.toLowerCase());
    divElement!.scrollIntoView({ behavior: "smooth" });
  };

  const NAVBAR_LINKS = [
    {
      name: "MANIFESTO",
      onClickLink: () => {
        console.log("ciao");
        scrollToSection("manifesto");
        setIsBurgerMenuOpen(false);
      },
    },
    // {
    //   name: 'GOALS',
    //   onClickLink: () => {
    //     scrollToSection('goals');
    //     setIsBurgerMenuOpen(false);
    //   },
    // },
    {
      name: "APP",
      onClickLink: () => {
        scrollToSection("application");
        setIsBurgerMenuOpen(false);
      },
    },
    {
      name: "TEAM",
      onClickLink: () => {
        scrollToSection("team");
        setIsBurgerMenuOpen(false);
      },
    },
    {
      name: "FAQ",
      onClickLink: () => {
        scrollToSection("faq");
        setIsBurgerMenuOpen(false);
      },
    },
  ];

  return (
    <>
      {/* DESKTOP */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 ">
        <div className="items-center justify-between hidden xl:flex">
          <div className="cursor-pointer xl:w-300" onClick={handleClickHome}>
            <div className="w-20 h-20">
              <img src={LogoPng} alt="logo" />
            </div>
          </div>
          <div className="flex">
            {NAVBAR_LINKS.map((navbarLink, index) => {
              return (
                <p
                  className="text-3xl font-semibold text-white uppercase cursor-pointer px-7 font-brush"
                  onClick={navbarLink.onClickLink}
                  key={index}
                >
                  {navbarLink.name}
                </p>
              );
            })}
          </div>
          <div className="flex cursor-pointer xl:w-300">
            <div className="w-8 h-8">
              <img
                src={TwitterPng}
                alt="twitter"
                onClick={handleClickTwitter}
              />
            </div>
            <div className="w-8 h-8 ml-4">
              <img
                src={OpenseaPng}
                alt="opensea"
                onClick={handleClickOpensea}
              />
            </div>
            <div className="w-8 h-8 ml-4 mr-4">
              <img
                src={InstagramPng}
                alt="instagram"
                onClick={handleClickInstagram}
              />
            </div>
            <div className="w-8 h-8 mt-1 ml-1 mr-4">
              <img
                src={DiscordPng}
                alt="discord"
                onClick={handleClickDiscord}
              />
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 xl:hidden">
        <div className="flex items-center justify-between ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-white cursor-pointer w-9 h-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleClickBurgerMenu}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
        </div>
        {/* MOBILE MENU */}
        {isBurgerMenuOpen && (
          <div className="items-start lg:hidden">
            {NAVBAR_LINKS.map((navbarLink, index) => {
              return (
                <p
                  className="py-1 text-2xl font-semibold text-white uppercase cursor-pointer font-brush"
                  onClick={navbarLink.onClickLink}
                  key={index}
                >
                  {navbarLink.name}
                </p>
              );
            })}
            <div className="flex mt-2">
              <div
                className="cursor-pointer w-7 h-7"
                onClick={handleClickTwitter}
              >
                <img src={TwitterPng} alt="twitter-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={handleClickOpensea}
              >
                <img src={OpenseaPng} alt="opensea-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={handleClickInstagram}
              >
                <img src={InstagramPng} alt="instagram-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={handleClickDiscord}
              >
                <img src={DiscordPng} alt="discord-logo" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
