import AngryWomenColorWhite from 'assets/logo.png';

const Manifesto = () => {
  return (
    <div className="h-full pb-5 bg-cover bg-wall-gradient" id="manifesto">
      <div className="grid place-items-center">
        <div className="px-4 my-2 lg:my-20 max-w-7xl">
          <p className="pt-5 pb-8 text-5xl font-semibold text-center lg:text-6xl font-brush text-manifesto">
            MANIFESTO
          </p>
          <div className="border-2 border-white rounded">
            <p className="px-5 py-5 text-justify text-white lg:leading-loose lg:px-10 lg:py-10 text-semibold text-l lg:text-3xl font-montserrat">
              We want to change society's portrayal of women's anger and show it
              as a healthy and powerful reaction to systemic inequalities and
              discrimination. We believe that anger is a motor that can drive
              positive change. We aim to fight for gender equality through
              concrete action in Web3
            </p>
            <div className="px-5 lg:flex">
              <p className="py-1 text-justify text-white lg:leading-10 lg:py-10 text-semibold text-l lg:text-3xl font-montserrat">
                Our core values:
              </p>
              <p className="py-1 mt-1 text-xl text-justify text-white lg:leading-10 lg:px-5 lg:py-10 text-semibold lg:text-4xl font-brush">
                EMPOWERMENT - SORORITY - ACTION
              </p>
            </div>
            <div className="flex justify-end px-5 py-5 ">
              <div className="w-20 h-20">
                <img src={AngryWomenColorWhite} alt="angry-women-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manifesto;
