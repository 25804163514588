import AngryWomenApplicationDesktopImage from "assets/vgt_interactions.png";

const Analytics = () => {
  return (
    <div className="py-10 bg-background" id="analytics">
      <div className="flex justify-center">
        <div>
          <p className="pt-5 pb-5 text-4xl font-semibold lg:text-6xl font-brush text-description">
            Very Good Tool
          </p>
        </div>
      </div>
      <div className="items-center justify-center px-4 lg:flex mx-20">
        <div className="pr-5 lg:w-1/2">
          <p className="pt-5 text-justify lg:w-4/5 lg:leading-10 text-l lg:text-xl font-montserrat">
            We are building a platform allowing you to discover new
            opportunities in the NFT space by providing you with valuable,
            curated, user-friendly social media insights.
          </p>
          <p className="text-justify lg:w-4/5 pt-5 lg:leading-10 text-l lg:text-xl font-montserrat">
            You will be able to explore and assess your NFT communities' social
            media reach as well as other market indicators.
          </p>
          <p className="pt-5 lg:leading-10 text-l lg:text-xl font-montserrat">
            COMING SOON!
          </p>
        </div>
        <div className="flex justify-center pt-10">
          <div>
            <img
              src={AngryWomenApplicationDesktopImage}
              alt="angry-woman-application"
            />
          </div>
        </div>
        {/* <div className="block md:hidden">
          <div>
            <img
              src={AngryWomenApplicationMobileImage}
              alt="angry-woman-application"
            />
          </div>
        </div> */}
      </div>
      <div className="flex justify-center lg:block">
        <div className="items-center flex flex-col my-7">
          <button
            onClick={() => {}}
            className="px-6 py-3 text-xl font-bold text-white bg-teal-700 rounded-full cursor-pointer font-montserrat mt-7 hover:bg-teal-900"
          >
            <div className="flex align-middle">
              <div className="pt-1 pr-2">VeryGoodTool.xyz</div>
              <span className="pt-1"></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
