import React from 'react';
import { Main } from './pages';

const AngryWomenConsole = `
█████  ███    ██  ██████  ██████  ██    ██     ██     ██  ██████  ███    ███ ███████ ███    ██ 
██   ██ ████   ██ ██       ██   ██  ██  ██      ██     ██ ██    ██ ████  ████ ██      ████   ██ 
███████ ██ ██  ██ ██   ███ ██████    ████       ██  █  ██ ██    ██ ██ ████ ██ █████   ██ ██  ██ 
██   ██ ██  ██ ██ ██    ██ ██   ██    ██        ██ ███ ██ ██    ██ ██  ██  ██ ██      ██  ██ ██ 
██   ██ ██   ████  ██████  ██   ██    ██         ███ ███   ██████  ██      ██ ███████ ██   ████
`;

console.log(AngryWomenConsole);

const App = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default App;
