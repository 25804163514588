import AngryWomenBannerImage from "assets/banner-image.png";

const Banner = () => {
  return (
    <div className="">
      <div className="items-center justify-center lg:flex">
        <div className="px-4 lg:pl-28 lg:px-20 lg:max-w-2xl">
          <div className="flex justify-center lg:block">
            <div>
              <p className="pt-5 font-semibold text-white text-8xl lg:text-9xl font-brush">
                ANGRY
              </p>
              <p className="pt-5 font-semibold text-white text-8xl lg:text-9xl font-brush">
                WOMEN
              </p>
            </div>
          </div>
          <p className="pt-5 text-justify text-white lg:leading-10 text-l lg:text-2xl font-montserrat">
            A feminist NFT collection of women using their anger as a driving
            force to shake the world.
          </p>
          {/* <div className="flex justify-center lg:block">
            <div>
              <button
                onClick={() => {}}
                className="px-6 py-3 text-xl font-bold text-white bg-teal-700 rounded-full cursor-pointer font-montserrat mt-7 hover:bg-teal-900"
              >
                MINTING SOON
              </button>
            </div>
          </div> */}
        </div>
        <div className="pt-5 lg:w-1/2">
          <img src={AngryWomenBannerImage} alt="angry-woman-description" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
