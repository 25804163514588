import TwitterPng from "assets/twitter.png";
import InstagramPng from "assets/instagram.png";
import LinkedinPng from "assets/linkedin.png";
import DiscordPnd from "assets/instagram.png";

import JoannaImage from "assets/team/johanna_pic.png";
import FinleyImage from "assets/team/finley_pic.png";
import CarolineImage from "assets/team/caro_pic.png";
import OscarImage from "assets/team/oscar_pic.png";
import BImage from "assets/team/b_pic.png";

const Team = () => {
  const teamInfosTop = [
    {
      image: CarolineImage,
      name: "Caroline Tamen",
      position: "Founder",
      instagram: "",
      discord: "",
      linkedin: "https://www.linkedin.com/in/caroline-tamen-1091/",
      twitter: "https://twitter.com/Tamcar10",
    },
    {
      image: FinleyImage,
      name: "Finley Belle (a.k. Zaza)",
      position: "Artist",
      instagram: "https://www.instagram.com/zaza_illustration/",
      discord: "",
      linkedin: "",
      twitter: "https://twitter.com/zaza_arts",
    },
    {
      image: JoannaImage,
      name: "Joanna Devergie",
      position: "Artistic Director",
      instagram: "",
      discord: "",
      linkedin: "https://www.linkedin.com/in/joannadevergie/",
      twitter: "https://twitter.com/Jojos_ananas",
    },
    // {
    //   image: '',
    //   name: '',
    //   position: '',
    //   instagram: '',
    //   discord: '',
    //   linkedin: '',
    //   twitter: '',
    // },
    {
      image: BImage,
      name: "Gonzalo",
      position: "Web3 developer",
      instagram: "",
      discord: "",
      linkedin: "https://www.linkedin.com/in/gonzalobarrientos/",
      twitter: "https://twitter.com/gonzalo90",
    },
    {
      image: OscarImage,
      name: "Oscar",
      position: "Legal",
      instagram: "",
      discord: "",
      linkedin: "https://www.linkedin.com/in/tangoxt/",
      twitter: "https://twitter.com/tescovalue",
    },
  ];

  // const teamInfosBottom = [
  //   // {
  //   //   image: ValeryImage,
  //   //   name: 'Valery Jena',
  //   //   position: 'Web3 developer',
  //   //   instagram: '',
  //   //   discord: '',
  //   //   linkedin: '',
  //   //   twitter: '',
  //   // },
  //   {
  //     image: BImage,
  //     name: 'Gonzalo',
  //     position: 'Web3 developer',
  //     instagram: '',
  //     discord: '',
  //     linkedin: 'https://www.linkedin.com/in/gonzalobarrientos/',
  //     twitter: 'https://twitter.com/gonzalo90',
  //   },
  //   // {
  //   //   image: FuriouzImage,
  //   //   name: 'Furiouz',
  //   //   position: 'Web3 developer',
  //   //   instagram: '',
  //   //   discord: '',
  //   //   linkedin: '',
  //   //   twitter: 'https://twitter.com/FuriouzAW',
  //   // },
  //   {
  //     image: OscarImage,
  //     name: 'Oscar',
  //     position: 'Legal',
  //     instagram: '',
  //     discord: '',
  //     linkedin: 'https://www.linkedin.com/in/tangoxt/',
  //     twitter: 'https://twitter.com/tescovalue',
  //   },
  // ];

  const handleClickSocial = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div className="flex justify-center bg-peach" id="team">
      <div className="max-w-7xl">
        <div>
          <p className="justify-center pt-10 pb-8 text-5xl font-semibold text-center text-white lg:text-6xl font-brush">
            TEAM
          </p>
        </div>
        <div className="justify-center lg:mx-10 lg:px-10 lg:flex">
          {teamInfosTop.map((teamInfo, index) => {
            return (
              <div key={index} className="px-5 pb-5">
                <div className="rounded-t-lg">
                  <img
                    src={teamInfo.image}
                    alt={`team-${index}`}
                    className="rounded-t-lg"
                  />
                </div>
                <div className="flex justify-around h-8 pt-1 bg-gray-700">
                  {teamInfo.instagram && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.instagram)}
                    >
                      <img src={InstagramPng} alt={`team-instagram-${index}`} />
                    </div>
                  )}
                  {teamInfo.discord && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.discord)}
                    >
                      <img src={DiscordPnd} alt={`team-discord-${index}`} />
                    </div>
                  )}
                  {teamInfo.twitter && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.twitter)}
                    >
                      <img src={TwitterPng} alt={`team-twitter-${index}`} />
                    </div>
                  )}
                  {teamInfo.linkedin && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.linkedin)}
                    >
                      <img src={LinkedinPng} alt={`team-linkedin-${index}`} />
                    </div>
                  )}
                </div>
                <div>
                  <p className="pt-5 font-semibold text-white text-l lg:text-xl font-montserrat">
                    {teamInfo.name}
                  </p>
                </div>
                <div>
                  <p className="pt-2 text-white text-l font-montserrat">
                    {teamInfo.position}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="lg:flex">
          {teamInfosBottom.map((teamInfo, index) => {
            return (
              <div key={index} className="px-5 pb-5">
                <div className="rounded-t-lg">
                  <img
                    src={teamInfo.image}
                    alt={`team-${index}`}
                    className="rounded-t-lg"
                  />
                </div>
                <div className="flex justify-around h-8 pt-1 bg-gray-700">
                  {teamInfo.instagram && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.instagram)}
                    >
                      <img src={InstagramPng} alt={`team-instagram-${index}`} />
                    </div>
                  )}
                  {teamInfo.discord && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.discord)}
                    >
                      <img src={DiscordPnd} alt={`team-discord-${index}`} />
                    </div>
                  )}
                  {teamInfo.twitter && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.twitter)}
                    >
                      <img src={TwitterPng} alt={`team-twitter-${index}`} />
                    </div>
                  )}
                  {teamInfo.linkedin && (
                    <div
                      className="flex justify-center w-6 h-6 rounded cursor-pointer"
                      onClick={() => handleClickSocial(teamInfo.linkedin)}
                    >
                      <img src={LinkedinPng} alt={`team-linkedin-${index}`} />
                    </div>
                  )}
                </div>
                <div>
                  <p className="pt-5 font-semibold text-white text-l lg:text-xl font-montserrat">
                    {teamInfo.name}
                  </p>
                </div>
                <div>
                  <p className="pt-2 text-white text-l font-montserrat">
                    {teamInfo.position}
                  </p>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Team;
