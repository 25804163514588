import { Navbar } from "controls/Navbar";
import { Analytics } from "sections/Analytics";
import { Banner } from "sections/Banner";
import { Collection } from "sections/Collection";
import { Footer } from "sections/Footer";
import { Manifesto } from "sections/Manifesto";
import { Team } from "sections/Team";
import { Faq } from "sections/Faq";

const Main = () => {
  return (
    <div>
      <div className="h-full banner-background">
        <Navbar />
        <Banner />
      </div>
      <Collection />
      <Manifesto />
      <Analytics />
      {/* <Goals /> */}
      {/* <Roadmap /> */}
      <Team />
      <Faq />
      <Footer />
    </div>
  );
};

export default Main;
