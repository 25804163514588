import { useState } from "react";
import ArrowDownSvg from "./ArrowDownSvg";
import ArrowUpSvg from "./ArrowUpSvg";

const faqInfos = [
  {
    title: "What is our mission?",
    content:
      "Build a strong community of badass women and allies invested in the Web3 space to impact the IRL world. Our way to do it starts by creating useful web3 tools for the NFT space and the workplace industry. ",
  },
  {
    title: "What is the Genesis collection?",
    content:
      "The Genesis Collection is a set of seven 1/1 NFTs that aims to raise awareness in the NFT space about gender-related topics such as Abortion rights, Street harassment, Racism and sexism at work, Obstetric and gynaecological violence, Harassment on streaming platforms, Mental load and contraception and Pride month.",
  },
  {
    title: "What is Very Good Tool?",
    content:
      "A social media analytics platform we are currently building that will help people to discover more opportunities in the NFT space as well as assess their current communities.",
  },
  {
    title: "What about the Workplace App?",
    content:
      "We want to develop an innovative Web3 application focused on improving women's access to information about the workplace by creating a support network and a platform that enforces transparency and accountability. We plan to provide a complete ecosystem that includes a workplace review and ranking, job postings and, above all, a safe community.",
  },
  // {
  //   title: "Will we give back to charity?",
  //   content:
  //     "Yes, we will be giving back to several charities whose missions and purposes align with AW. We will share more about them soon.",
  // },
  // {
  //   title: "When is the lauch day?",
  //   content: "We’re aiming by July 2022",
  // },
  {
    title: "Wen the generative pfp collection?",
    content:
      "We aim to launch our generative collection once we feel our community is strong enough to unleash the Angry Women and the utilities behind them (yes we still believe in utilities)! In short: TBD",
  },
  {
    title: "How do I buy an Angry Women NFT?",
    content: "You can buy using a Metamask Wallet",
  },
  // {
  //   title: 'What is the supply and mint price?',
  //   content: 'There will be 10,000 Angry Women. Mint price: TBA.',
  // },
  // {
  //   title: "Is there going to be a 1/1?",
  //   content: "Yes, there will be. The final number is TBA.",
  // },
  // {
  //   title: 'Is your contract gas efficient?',
  //   content: 'Yes, we’re using ERC-721a.',
  // },

  {
    title: "Are you going to shake the NFT space?",
    content:
      "Yes, we’re going to kick ass. We have a great team that is commited to impact this space and actually promote women and drive positive change",
  },
];

interface FaqComponentProps {
  title: string;
  content: string;
}

const FaqComponent = (faqComponent: FaqComponentProps) => {
  const [displayContent, setDisplayContent] = useState<boolean>(false);

  const handleClickFaqTitle = () => {
    setDisplayContent(!displayContent);
  };

  return (
    <div
      className="mb-5 border border-white rounded cursor-pointer faq-width faq-width-lg"
      onClick={handleClickFaqTitle}
    >
      <div className="w-full px-5 py-4 lg:px-10 lg:py-8">
        <div className="flex justify-between">
          <div>
            <p className="font-semibold text-white text-l lg:text-xl font-montserrat">
              {faqComponent.title}
            </p>
          </div>
          <div className="pt-2 pl-4">
            {displayContent ? ArrowDownSvg() : ArrowUpSvg()}
          </div>
        </div>
        {displayContent && (
          <div className="max-w-l">
            <p className="pt-5 text-white text-l lg:text-xl font-montserrat">
              {faqComponent.content}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const Faq = () => {
  return (
    <div className="banner-background">
      <div className="flex justify-center">
        <div className="max-w-2xl">
          <div id="faq">
            <p className="justify-center pt-5 pb-10 text-5xl font-semibold text-center text-white lg:text-6xl font-brush">
              FAQ
            </p>
          </div>
          <div>
            {faqInfos.map((faqInfo, index) => {
              return (
                <div key={index}>
                  <FaqComponent
                    title={faqInfo.title}
                    content={faqInfo.content}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
