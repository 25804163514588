import TwitterBlackPng from 'assets/twitter-black.png';
import InstagramBlackPng from 'assets/instagram-black.png';
import DiscordBlackPng from 'assets/discord-black.png';
import allLinks from 'utils/allLinks';
import AngryWomenColorLogo from 'assets/logo-color.png';

const handleClickSocial = (link: string) => {
  if (link === '') {
    alert('Coming soon!');
    return;
  }
  window.open(link, '_blank');
};

const Footer = () => {
  return (
    <div className="flex justify-center px-2 bg-backgroundLight">
      <div>
        <div className="flex justify-center pt-5">
          <div className="w-20 h-20 ">
            <img src={AngryWomenColorLogo} alt="angry-women-logo" />
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="w-8 h-8 cursor-pointer"
            onClick={() => handleClickSocial(allLinks.instagramLink)}
          >
            <img src={InstagramBlackPng} alt="instagram" />
          </div>
          <div
            className="w-8 h-8 mx-8 cursor-pointer"
            onClick={() => handleClickSocial(allLinks.discordLink)}
          >
            <img src={DiscordBlackPng} alt="discord" />
          </div>
          <div
            className="w-8 h-8 cursor-pointer"
            onClick={() => handleClickSocial(allLinks.twitterLink)}
          >
            <img src={TwitterBlackPng} alt="twitter" />
          </div>
        </div>
        <div>
          <p className="pt-5 pb-5 font-semibold text-gray-600 text-m lg:text-l font-montserrat">
            ©COPYRIGHT-2022 Angry Women Ltd. ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
